window.app.displayTemplates = (function() {
  let searchPage = function(jsondata) {
    let markup = '';

    for (let i = 0; i < jsondata.length; i++) {
      markup += "<div class='row'>";
      markup += "<div class='column small-12'>";
      markup += "<h2><a href='" + jsondata[i].Url + "'>" + jsondata[i].Title + '</a></h2>';
      markup += '<p>' + jsondata[i].Text + '</p>';
      markup += '</div>';
      markup += '</div>';
    }

    return markup;
  };

  let searchDropDown = function(jsondata) {
    let markup = '';

    for (let i = 0; i < jsondata.length; i++) {
      markup += "<li><a href='" + jsondata[i].Url + "'>" + jsondata[i].Title + '</a></li>';
    }

    return markup;
  };

  return {
    searchPage: searchPage,
    searchDropDown: searchDropDown
  };
})();
