import React from 'react';
import PropTypes from 'prop-types';
import scrollTo from 'scroll-into-view';
import { GoogleMaps } from '../googlemaps';
import debounce from 'lodash/debounce';
import { MapBlockContainer, ItemContainer, InfoArea, FilterWrapper } from './components';
import { GeoLocator } from '../geolocator';
import { SearchInput } from '../input';
import { MapService, FindService } from '../../api';
import { setupMutationObserver, profilToText, isSmall } from '../../utils';
import { BlockArea } from '../blockarea';
import { Flex } from '../flex';

class FastighetsMapBlock extends React.Component {
  static defaultProps = {
    allRealEstates: [],
    portfoljFilters: [],
    profilFilters: [],
    mapsKey: '',
    portfoljFiltersHeader: '',
    profilFiltersHeader: '',
    fallbackImage: '',
    heading: undefined,
    preamble: undefined,
    mainBody: undefined,
    image: undefined,
    contentLinkId: undefined,
    excludeRealEstates: [],
    includeRealEstates: []
  };

  static propTypes = {
    allRealEstates: PropTypes.array,
    filterBlocks: PropTypes.any,
    mapsKey: PropTypes.string,
    fallbackImage: PropTypes.string,
    heading: PropTypes.string,
    preamble: PropTypes.string,
    image: PropTypes.string,
    mainBody: PropTypes.string,
    contentLinkId: PropTypes.number,
    excludeRealEstates: PropTypes.array,
    includeRealEstates: PropTypes.array
  };
  constructor(props) {
    super(props);
    this._geoLocator = new GeoLocator();
    this.filterContainer = React.createRef();
    this.tooltipRef = React.createRef();
    this.fastighetsSlider = React.createRef();
    const { allRealEstates = [] } = props;
    this.handleWindowResize = debounce(this.handleWindowResize, 300);
    let converted = this.convertRealEstatesToMarker(allRealEstates);
    this.state = {
      isSearching: false,
      markers: converted,
      allMarkers: converted,
      selectedRealEstates: [],
      initialMarker: converted[0] || { lat: 59.329323, lng: 18.068581 },
      searchResult: [],
      searchCategories: [],
      searchPortfolj: [],
      searchProfil: [],
      searchAttribute: [],
      searchValue: '',
      userRejectedGeoLocation: false,
      userCoordinates: undefined,
      reset: false,
      showReset: false,
      tooltip: { isActive: false, text: '' }
    };
  }

  componentDidMount = async () => {
    this.setUserCoords();
    this.setMapHeight();
    window.addEventListener('resize', this.handleWindowResize);
    setupMutationObserver(this.filterContainer.current, this.handleWindowResize);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      tooltip: { isActive, ref }
    } = this.state;
    const {
      tooltip: { isActive: prevIsActive }
    } = prevState;

    if (isActive === prevIsActive || !isActive) return null;
    if (!ref || !this.tooltipRef || !this.tooltipRef.current) return null;
    const { top, left, width: markerWidth } = ref.getBoundingClientRect();
    const { width, height } = this.tooltipRef.current.getBoundingClientRect();
    const topPos = top - height;
    const leftPos = left - width / 2 + markerWidth / 2;

    this.tooltipRef.current.style.left = `${leftPos}px`;
    this.tooltipRef.current.style.top = `${topPos}px`;
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleWindowResize);
  };

  handleWindowResize = () => {
    this.setMapHeight();
  };

  setMapHeight = () => {
    if (!this.filterContainer.current) return null;
    if (isSmall()) return null;
    const { height } = this.filterContainer.current.getBoundingClientRect();
    this.setState({ mapHeight: `${height}px` });
  };

  handleCardChange = () => {
    this.setMapHeight();
  };

  convertRealEstatesToMarker = (realEstates = []) => {
    const filteredRealestated =
      realEstates.filter(
        ({ longitude, latitude, matchingProjectPropertyValue }) =>
          !!latitude && !!longitude && !!matchingProjectPropertyValue
      ) || [];

    if (!filteredRealestated || !filteredRealestated.length) return [];

    const converted = filteredRealestated.map(
      ({ longitude, latitude, matchingProjectPropertyValue, ...rest }) => {
        return {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
          text: profilToText(rest),
          id: matchingProjectPropertyValue,
          data: rest
        };
      }
    );

    return converted;
  };

  setUserCoords = async () => {
    const { latitude, longitude } = await this._geoLocator.getCoordinates();
    if (!latitude || !longitude) {
      this.setState({ userRejectedGeoLocation: true });
      return;
    }

    this.setState({
      userRejectedGeoLocation: false,
      userCoordinates: { lat: latitude, lng: longitude }
    });
  };

  handleChildSelect = (selectedMarkers = []) => {
    const { allRealEstates } = this.props;

    const selectedRealEstates = selectedMarkers.map(({ id }) => {
      return allRealEstates.find(
        ({ matchingProjectPropertyValue }) => id === matchingProjectPropertyValue
      );
    });
    this.setState({ selectedRealEstates: selectedRealEstates });

    if (!selectedRealEstates.length) return null;

    try {
      if (!isSmall()) return null;
      if (!this.fastighetsSlider || !this.fastighetsSlider.current) return null;
      const { height } = document.querySelector('[data-nav-parent]').getBoundingClientRect();
      scrollTo(this.fastighetsSlider.current, {
        align: {
          top: 0,
          topOffset: height + 5
        }
      });
    } catch (e) {
      console.error(`couldn't scroll to element`);
    }
  };

  handleCloseInformation = () => {
    this.setState({ selectedRealEstates: false });
  };

  handleSearchDone = result => {
    const { isOk, data } = result;

    if (isOk) {
      this.setState({
        searchResult: data,
        markers: this.convertRealEstatesToMarker(data),
        isSearching: false
      });
      return;
    }
    //Ska vi resetta resultatet här verkligen?
    this.setState({
      isSearching: false,
      markers: []
    });
  };

  handleSearchStart = () => {
    this.setState({ searchResult: [], isSearching: true });
  };
  handleSearchValueUpdate = (value = '') => {
    this.setState({ isSearching: value.length, searchValue: value });
  };

  handleFilterRemove = filter => {
    const { searchPortfolj = [], searchProfil = [], searchAttribute = [] } = this.state;
    const { type, value } = filter;
    const isProfile = type === 'profil';
    const isPortfolj = type === 'portfolj';
    const isAttribute = type === 'attribute';
    this.setState({
      searchPortfolj: isPortfolj ? searchPortfolj.filter(v => v !== value) : searchPortfolj,
      searchProfil: isProfile ? searchProfil.filter(v => v !== value) : searchProfil,
      searchAttribute: isAttribute ? searchAttribute.filter(v => v !== value) : searchAttribute
    });
  };

  handleFilterClear = () => {
    this.setState({
      searchPortfolj: [],
      searchProfil: [],
      searchAttribute: []
    });
  };

  handleFilterAdd = filter => {
    const { searchPortfolj = [], searchProfil = [], searchAttribute = [] } = this.state;
    const { type, value } = filter;
    const isProfile = type === 'profil';
    const isPortfolj = type === 'portfolj';
    const isAttribute = type === 'attribute';
    this.setState({
      searchPortfolj: isPortfolj ? [...searchPortfolj, value] : searchPortfolj,
      searchProfil: isProfile ? [...searchProfil, value] : searchProfil,
      searchAttribute: isAttribute ? [...searchAttribute, value] : searchAttribute
    });
  };

  handleReset = () => {
    const { allRealEstates } = this.props;
    this.setState(
      {
        reset: true,
        selectedRealEstates: [],
        markers: this.convertRealEstatesToMarker(allRealEstates)
      },
      () => {
        this.setState({ reset: false });
      }
    );
  };

  getSearchParams = () => {
    const { searchPortfolj, searchProfil, searchAttribute } = this.state;
    const { includeRealEstates, excludeRealEstates } = this.props;

    const result = {
      portfolio: (searchPortfolj || []).length ? searchPortfolj : undefined,
      profile: (searchProfil || []).length ? searchProfil : undefined,
      attribute: (searchAttribute || []).length ? searchAttribute : undefined,
      exclude: (excludeRealEstates || []).map(({ contentLinkId }) => contentLinkId),
      include: (includeRealEstates || []).map(({ contentLinkId }) => contentLinkId)
    };

    return result;
  };

  onChildActiveChange = props => {
    if (!props) return null;

    const { isActive, ref, text } = props;

    if (!ref) return null;
    this.setState({ tooltip: { text, isActive: isActive, ref } });
  };

  render() {
    const {
      markers = [],
      // eslint-disable-next-line
      selectedRealEstates,
      userCoordinates,
      initialMarker,
      isSearching,
      mapHeight,
      reset,
      searchProfil,
      searchAttribute,
      searchPortfolj,
      searchValue
    } = this.state;
    // eslint-disable-next-line
    const { mapsKey, fallbackImage, heading, image, preamble, mainBody, filterBlocks } = this.props;
    const initialCenter = { lat: initialMarker.lat, lng: initialMarker.lng };
    const searchParameters = this.getSearchParams();
    const filters = (filterBlocks || []).map(block => {
      return {
        reset: reset,
        onFilterAdd: this.handleFilterAdd,
        onFilterRemove: this.handleFilterRemove,
        onFilterClear: this.handleFilterClear,
        ...block
      };
    });

    const showResetButton =
      searchPortfolj.length || searchProfil.length || searchValue.length || searchAttribute.length
        ? true
        : false;

    const resettable =
      searchPortfolj.length || searchProfil.length || searchAttribute.length ? true : false;

    return (
      <React.Fragment>
        <MapBlockContainer>
          <React.Fragment>
            <ItemContainer w={100} ref={this.filterContainer}>
              <Flex
                className="item-container-flex"
                column
                style={{ backgroundImage: `url(${image})` }}>
                <InfoArea>
                  <div className="info-area-inner">
                    {heading && <h1>{heading}</h1>}
                    {preamble && <p className="icaf-intro-text-1">{preamble}</p>}
                    {mainBody && (
                      <div
                        style={{ width: '100%' }}
                        dangerouslySetInnerHTML={{ __html: mainBody }}
                      />
                    )}
                  </div>
                </InfoArea>
                <FilterWrapper hasFilters={resettable} onClickReset={this.handleReset}>
                  <SearchInput
                    onClear={this.handleReset}
                    showReset={showResetButton}
                    isSearching={isSearching}
                    reset={reset}
                    params={searchParameters}
                    onSearchStart={this.handleSearchStart}
                    onSearchValueUpdate={this.handleSearchValueUpdate}
                    searchFunction={MapService.findFastigheter}
                    autocompleteFunction={FindService.getAutocomplete}
                    onSearchDone={this.handleSearchDone}
                    placeholder="Sök på fastigheter eller plats..."
                  />
                  <BlockArea contentarea={filters} />
                </FilterWrapper>
              </Flex>
            </ItemContainer>
            <ItemContainer showOverlay={isSearching} w={100}>
              <GoogleMaps
                reset={reset}
                height={mapHeight}
                onChildSelect={this.handleChildSelect}
                initialCenter={initialCenter}
                userCoordinates={userCoordinates ? userCoordinates : undefined}
                markers={markers}
                mapsKey={mapsKey}
                onChildActiveChange={this.onChildActiveChange}
                onChildExit={this.onChildEnter}
              />
            </ItemContainer>
          </React.Fragment>
        </MapBlockContainer>
      </React.Fragment>
    );
  }
}

export { FastighetsMapBlock };
