// prettier-ignore
(function () {
    let divs = $('div[class*="-wrapper"]');

    let count = 1;
    for (let i = 0; i < divs.length; i++) {
        if (!divs[i].className.match('__') && !divs[i].className.match('inner-wrapper')) {
            if (count % 2 == 0) {
                divs[i].style.backgroundColor = '#F5F5F5';
                $(divs[i]).addClass('blue-bg');
            } else {
                divs[i].style.backgroundColor = '#FEF7F3';
                $(divs[i]).addClass('red-bg');
            }
            count++;
        } else if (divs[i].className.match('linkblock-wrapper__main')) {
            //console.log(divs[i]);
            if (count % 2 == 0) {
                divs[i].style.backgroundColor = '#F5F5F5';
                $(divs[i]).addClass('blue-bg');
            } else {
                divs[i].style.backgroundColor = '#FEF7F3';
                $(divs[i]).addClass('red-bg');
            }
            count++;
        }
    }

    $('.tabbusinessnameblock').each(function () {
        let elem = $(this);
        let parent = elem.parents('.info-wrapper').first();
        let parentBackgroundColor = parent.css("background-color");
        if (elem.css('background-color') == "rgb(245, 245, 245)") {
            elem.removeClass('blue-bg');
            elem.addClass('red-bg');
        } else if (elem.css('background-color') == "rgb(254, 247, 243)") {
            elem.removeClass('red-bg');
            elem.addClass('blue-bg');
        }
        elem.css('background-color', parentBackgroundColor);
    });
})();
