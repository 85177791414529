(function() {
  jQuery('[data-icaf-load-more]').on('click', function(e) {
    e.preventDefault();

    let $that = jQuery(this);
    let ajaxUrl = $that.attr('data-icaf-load-more-ajax-url');
    let $ajaxLoadMoreTarget = jQuery($that.attr('data-icaf-load-more-ajax-target'));
    let $pageCountTarget = jQuery($that.attr('data-icaf-load-more-pagecount-target'));
    let shouldScroll = $that.attr('data-icaf-load-more-should-scroll');
    let dataType = $that.attr('data-icaf-load-more');

    // Set in ajax callback
    let loadMoreUrl = '';
    let view = '';
    let hasMoreHits = false;

    $.get(ajaxUrl).then(function(response) {
      if (dataType == 'xml') {
        let tmpResponse = response;
        response = jQuery("<div class='xmldata'></div>");
        response.html(tmpResponse);

        loadMoreUrl = jQuery(response)
          .find('input[type=hidden]:first-child')
          .val();
        hasMoreHits = loadMoreUrl;
        view = jQuery(response).unwrap();
      } else {
        loadMoreUrl = response.loadMoreUrl;
        hasMoreHits = response.hasMoreHits;
        view = window.app.displayTemplates.searchPage(response.hits.Hits);
      }

      let $lastRow = $ajaxLoadMoreTarget.find('.row:last-child');

      $that.attr('data-icaf-load-more-ajax-url', loadMoreUrl);

      $ajaxLoadMoreTarget.append(view);

      $pageCountTarget.html($ajaxLoadMoreTarget.find('>.row').length);

      if (hasMoreHits) {
        $that
          .parents('.row')
          .first()
          .removeClass('hide');
      } else {
        $that
          .parents('.row')
          .first()
          .addClass('hide');
      }

      jQuery(document).foundation('equalizer', 'reflow');
      jQuery(document).foundation('interchange', 'reflow');

      if (!shouldScroll) {
        return;
      }

      jQuery('html, body').animate(
        {
          scrollTop: $lastRow.offset().top
        },
        600
      );
    });
  });
})();
