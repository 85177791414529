function initMap() {
  if (document.getElementById('map') == null) {
    return;
  }

  let locArray = JSON.parse(document.getElementById('map').getAttribute('data-map-locations'));
  let infoWindowContent = document.getElementById('map').getAttribute('info-window-content');
  let displayinfoWindowOnLoad = true;

  if (infoWindowContent == null) {
    infoWindowContent = '';
    displayinfoWindowOnLoad = false;
  }

  let latitude = parseFloat(locArray[0].lat.replace(',', '.'));
  let longitude = parseFloat(locArray[0].lng.replace(',', '.'));

  let myLatLng = { lat: latitude, lng: longitude };

  let map = new google.maps.Map(document.getElementById('map'), {
    center: { lat: latitude, lng: longitude },
    zoom: 9
  });

  let contentString =
    '<div class="mapblock-text-area-inner">' +
    '<h2 class="text-center">' +
    locArray[0].title +
    '</h2>' +
    '<div class="icaf-tiny-mce-container">' +
    infoWindowContent +
    '</div>' +
    '</div>';

  let infowindow = new google.maps.InfoWindow({
    content: '<div id="iw_content">' + contentString + '</div>'
  });

  let marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: 'Klicka för info!'
  });

  marker.addListener('click', function() {
    infowindow.open(map);
    map.setCenter(marker.getPosition());
  });

  marker.addListener('click', function() {
    //Check if in mobile mode
    if (jQuery('#iw_content').css('width') == '200px') {
      jQuery('.gm-style-iw')
        .parent()
        .css({
          left: '1.5625rem',
          top: '3.5625rem'
        });
    } else {
      jQuery('.gm-style-iw')
        .parent()
        .css({
          left: '6.5625rem',
          top: '6.5625rem'
        });
    }

    //sets style of infowindow
    jQuery('.gm-style-iw')
      .prev()
      .html('');
    jQuery('.gm-style-iw').css({
      'background-color': 'white',
      padding: '15px',
      left: '20px'
    });
  });

  if (displayinfoWindowOnLoad) {
    google.maps.event.addListenerOnce(map, 'idle', function() {
      google.maps.event.trigger(marker, 'click');
    });
  }
}

initMap();
