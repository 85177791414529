import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import React from 'react';
import { ICA_RED, WHITE, LIGHT_GRAY } from '../../../constants/colors';
import { TEXT_REGULAR } from '../../../constants/fonts';
export const FilterLabel = styled.label.attrs(() => ({}))`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  background: ${WHITE};
  display: flex !important;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${TEXT_REGULAR};
  text-transform: uppercase;
  color: ${ICA_RED};
  z-index: 1;

  &:hover {
    background: ${LIGHT_GRAY};
  }

  img {
    z-index: 0;
    height: ${({ largeimage }) => (largeimage ? '2.5rem' : '1.7rem')};
    /* margin-top: ${({ largeimage }) => (largeimage ? 0 : 'auto')}; */
    margin-bottom: 3px;
    pointer-events: none;
    image-rendering: crisp-edges;
    image-rendering: -webkit-optimize-contrast;
  }
  p {
    width: 100%;
    text-align: center;
    margin: 0;
    pointer-events: none;
    color: ${ICA_RED};
    line-height: 1em;
    font-size: 0.65rem;
    @media only screen and (min-width: 900px) {
      display:${({ hiddenOnDesktop }) => (hiddenOnDesktop ? 'none' : 'inline-block')}
    }
    
  }
  &:after {
    content: '';
    position: absolute;
  }
`;

export const FilterCheckbox = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  cursor: pointer;
  display: none;
  &:checked + ${FilterLabel} {
    background: ${({ selectedbg }) => (selectedbg ? selectedbg : ICA_RED)};
    p {
      color: ${WHITE};
    }
  }
`;

export const FilterWrapperItem = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  position: relative;
  flex-wrap: wrap;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 33.3333%;
    padding: 0.5rem;
  }
`;

export const FilterContainer = ({
  image,
  value,
  header,
  type,
  onChange,
  isSelected,
  selectedImage,
  selectedBackgroundColor = ICA_RED,
  noTextDesktop
}) => {
  const imageWhenSelected = selectedImage || image;
  return (
    <FilterWrapperItem>
      <FilterCheckbox
        selectedbg={selectedBackgroundColor}
        checked={isSelected}
        onChange={() => onChange({ value: value, type: type })}
        id={value}
        value={value}
        test={image}
      />
      <FilterLabel
        hiddenOnDesktop={noTextDesktop}
        largeimage={header ? false : true}
        htmlFor={value}>
        {image && (
          <img
            src={isSelected ? imageWhenSelected : image}
            alt={`ikon för fastigheten ${header}`}
          />
        )}
        <p>{header}</p>
      </FilterLabel>
    </FilterWrapperItem>
  );
};

FilterContainer.propTypes = {
  onChange: () => {},
  isSelected: false,
  header: undefined,
  type: 'unknown',
  selectedImage: undefined,
  noTextDesktop: false
};

FilterContainer.propTypes = {
  image: PropTypes.string.isRequired,
  selectedImage: PropTypes.string,
  value: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  type: PropTypes.string,
  noTextDesktop: PropTypes.bool,
  selectedBackgroundColor: PropTypes.string
};
