import styled from 'styled-components/macro';
import { TEXT_GRAY } from '../../../constants/colors';
import { TEXT_REGULAR } from '../../../constants/fonts';
import { FilterWrapperItem, FilterLabel } from './FilterContainer';

export const FilterBlockContainer = styled.div`
  display: flex;
  max-width: 460px;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
  label {
    text-transform: uppercase;
    font-family: ${TEXT_REGULAR};
    font-weight: bold;
    font-size: 1rem;
    color: ${TEXT_GRAY};
    line-height: 2em;
  }

  @media only screen and (max-width: 900px) {
    margin-bottom: 0.3rem;
  }
`;

export const FilterItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.8rem;

  @media only screen and (max-width: 900px) and (min-width: 620px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 620px) and (min-width: 420px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const FilterItemColumn = styled.div`
  display: none;
  flex-direction: column;

  @media only screen and (max-width: 900px) {
    display: flex;
  }

  ${FilterWrapperItem} {
    height: auto;
    margin-bottom: 0.2rem;

    img {
      display: none;
    }
  }

  ${FilterLabel} {
    padding: 1rem;

    p {
      font-size: 1rem;
      text-align: left;
    }
  }
`;
